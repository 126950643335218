import { useSelector, useStore } from "react-redux";
import { store } from "../store/store";
import BookingOverview from "../Components/BookingOverview";
import NotFound from "../Components/NotFound/NotFound";
import NmsConsent from "../pages/NmsConsent/NmsConsent";
import { setAppLoading } from "../dispatchers/appDispatchers";
import {
  getAllConsentData,
  setConsentLoading,
} from "../dispatchers/consentDispatchers";
import { setIsTemplateLoadingData, setInfectionServayData } from "../dispatchers/templateSectionDispatchers";
import { logError } from "../services/logService";
import { defer } from "react-router-dom";
import NewMedicineService from "../pages/NmsConsent/components/NewMedicineService";
import Abort from "../pages/NmsConsent/components/Abort";
import MoreInfo from "../pages/NmsConsent/components/MoreInfo";
import PersonalInfo from "../pages/NmsConsent/components/PersonalInfo";
import MakeBooking from "../pages/NmsConsent/components/MakeBooking";
import SuccessPage from "../pages/NmsConsent/components/SuccessPage";
import Dashboard from "../pages/dashboard/dashboard";
import Login from "../pages/login/login";
import GeneralInstrauctions from "../pages/GeneralInstrauctions/GeneralInstrauctions";
import QuestionAir from "../pages/QuestionAir/QuestionAir";
import BlankPage from "../pages/NmsConsent/components/BlankPage";
import UserConfirmation from "../pages/NmsConsent/components/UserConfirmation";
import WarningMessage from "../pages/NmsConsent/components/WarningMessage";
import CancelBooking from "../pages/NmsConsent/components/CancelBooking";
export const useBookingApp = () => {
  // #region Hook Constants
  const store = useStore();
  const appState = useSelector((state) => state.app);
  const consentState = useSelector((state) => state.consent);
  const templateSectionState = useSelector((state) => state.templateSection);
  // #endregion
  // #region API getter/caller functions for components usage to dispatch REDUX extra reducers
  // #endregion
  // #region Loader Handlers
  // #endregion
  // #region Loaders for APIs call
  const consentFormLoader = async ({ params }) => {
    const { serviceId } = params;
    setConsentLoading(true);
    let appData = null;
    const finallyHandler = () => {
      setConsentLoading(false);
      setAppLoading(false);
    };
    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setConsentLoading(false);
      setAppLoading(false);
      return error;
    };
    const appDataHandler = () => {
      try {
        const consentData = getAllConsentData(serviceId);
        return consentData;
      } catch (error) {
        logError(error);
        setConsentLoading(false);
        setAppLoading(false);
        return error;
      }
    };
    appData = appDataHandler().catch(errorHandler).finally(finallyHandler);
    return defer({ appData });
  };
  // #endregion
  // #region Routers and config related function
  const loadRoutes = () => {
    let customRoutes = [

      {
        path: "/login",
        element: <Login />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },

      {
        path: "/Dashboard",
        element: <Dashboard />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },

      {
        path: "/GeneralInstructions",
        element: <GeneralInstrauctions />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },

      {
        path: "/questionSection/:serviceId",
        element: <QuestionAir />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },

      {
        path: "/",
        element: <BlankPage />,
        // element: <BookingOverview />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },

      {
        path: "/n/:serviceId",
        element: <NmsConsent />,
        loader: consentFormLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/NewMedicineService",
        element: <NewMedicineService />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/UserConfirmation",
        element: <UserConfirmation />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/Abort",
        element: <Abort />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/moreInfo",
        element: <MoreInfo />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/personalInfo",
        element: <PersonalInfo />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/makeBooking",
        element: <MakeBooking />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/successPage",
        element: <SuccessPage />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/cancel",
        element: <CancelBooking />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/bookingUpdate",
        element: <WarningMessage />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "*",
        element: <NotFound />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
    ];
    return customRoutes;
  };
  // #endregion
  // #region Return Object
  return {
    appState,
    consentState,
    templateSectionState,
    loadRoutes,
  };
  // #endregion
};
export { store };
export default useBookingApp;
