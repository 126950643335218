import React from "react";
import "./../../App.css";
import "../../Assets/css/NmsConsent.css";
import { useNavigate } from "react-router-dom";
import AsyncRendererRouter from "../../Components/AsyncRendererRouter/AsyncRendererRouter";
import CustomLoader from "../../Components/controls/CustomLoader";
import { useLoaderData } from "react-router-dom";
import useBookingApp from "../../hooks/useBookingApp";
import NHSLogo from "../../Assets/nhsLogo.jpg";

function NmsConsent() {
  const { consentState } = useBookingApp();
  const { isConsentLoading, consentData } = consentState;
  const [{ PharmacyName } = {}] = consentData ?? [];

  const navigate = useNavigate();

  function handleStepNo() {
    navigate("/UserConfirmation");
  }

  const { appData } = useLoaderData();

  return (
    <AsyncRendererRouter
      isLoading={isConsentLoading}
      resolve={appData}
      loadingElement={
        <>
          <div className="menubar_container">
            <div class="nav">
              <input type="checkbox" id="nav-check" />
              <div class="nav-header">
                <div class="nav-title">
                  {/* <img src={AppLogo} alt='Logo' /> */}
                </div>
                <div className="header_logo_wrapper">
                  <img src={NHSLogo} alt="Logo" className="nhs_icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="custom_loader">
            <CustomLoader />
          </div>
        </>
      }
    >
      <div className="user-confirmation">
        <div className="menubar_container">
          <div class="nav">
            <input type="checkbox" id="nav-check" />
            <div class="nav-header">
              <div>
                <span className="pharmacy_name">{PharmacyName || ""}</span>
              </div>
              <div className="header_logo_wrapper">
                <img src={NHSLogo} alt="Logo" className="nhs_icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="user-confirmation-form">
          <div className="nhsuk-width-container ">
            <main className="nhsuk-main-wrapper " id="maincontent" role="main">
              <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-two-thirds">
                  <h2>Pharmacy Service</h2>
                  <br></br>
                  <p>
                    You recently had some new medication from {PharmacyName} and are eligible for FREE advice from a pharmacist.
                  </p>
                  <p>
                  The consultation, conducted over the phone, 
                  will help ensure you're taking the medication correctly and 
                  also allows you to raise any concerns about the medication
                  </p>
                  <p>Click the button below to sign up for the service</p>
                  <a className="nhsuk-button" onClick={handleStepNo}>
                    Sign Up
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
export default NmsConsent;
