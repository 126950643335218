import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  saveConsentData,
  setConsentLoading,
  setConsentFormModel,
} from "../../../dispatchers/consentDispatchers";
import { v4 as uuidv4 } from "uuid";
import {
  showSuccess,
  showError,
} from "../../../Components/ToastedMessage/ToastedMessage";
import useBookingApp from "../../../hooks/useBookingApp";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import CustomDateTimePicker from "./CustomDateTimePicker";
import { serviceType } from "../../../Enum/Enums";
import CustomLoader from "../../../Components/controls/CustomLoader";
import NHSLogo from "../../../Assets/nhsLogo.jpg";

function MakeBooking() {
  const navigate = useNavigate();
  const { consentState } = useBookingApp();
  const { consentData, healthyAdvice, isConsentLoading, isReschedule } =
    consentState;
  const [{ PatientID, PatientName, ClinicalServiceID, PharmacyName } = {}] =
    consentData ?? [];
  const currentDate = dayjs();
  const defaultDate = currentDate.add(7, "day").startOf("day");
  const defaultTime = dayjs().set("hour", 12).set("minute", 0);
  const defaultDateTime = defaultDate
    .set("hour", defaultTime.hour())
    .set("minute", defaultTime.minute());

  const [appointmentDate, setAppointmentDate] = useState(defaultDateTime);
  const [comments, setComments] = useState("");

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSuccessPage() {
    setConsentLoading(true);
    const obj = {
      patientId: PatientID,
      consent: true,
      consentSignature: "",
      patientName: PatientName,
      prescriptionItemId: uuidv4(),
      PrescriptionId: uuidv4(),
      ethnicity: 1,
      contactType: 1,
      contactNumber: "",
      dateOfIntervention: appointmentDate,
      sendSms: false,
      healthyLivingAdvice: healthyAdvice,
      medications: null,
      isProcessedNMS: false,
      patientMedicationId: [],
      isDeclined: false,
      createdBy: "user",
      prescriptionIncomingId: uuidv4(),
      Notes: comments,
      Status: isReschedule ? 3 : 2,
      ServiceType: 1,
      Charges: "25",
      AlreadyPaid: 1,
    };
    console.log(obj);
    const reponse = saveConsentData(ClinicalServiceID, obj);
    reponse.then((result) => {
      setConsentFormModel(obj);
      setConsentLoading(false);
      if (result) {
        navigate("/successPage");
      } else {
        showError("Consent could not be saved.", true, false);
      }
    });
  }

  return (
    <div className="user-confirmation booking_date_time_selection_wrapper">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{PharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" /> 
            </div>
          </div>
        </div>
      </div>
      {isConsentLoading ? (
        <div className="custom_loader">
          <CustomLoader />
        </div>
      ) : (
        <div className="user-confirmation-form nhsuk_container">
          <div className="nhsuk-width-container ">
            <main
              className="nhsuk-main-wrapper nhsuk-main-wrapper--s"
              id="maincontent"
              role="main"
            >
              <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-two-thirds">
                  <div className="nhsuk-form-group">
                    <fieldset className="nhsuk-fieldset">
                      <div className="booking_content">
                        <h2>Book your call</h2>
                        <br></br>
                        <p>
                          We're ready to make your booking to speak to a
                          pharmacist. This will be around 7 days after you've
                          had a chance to get use to your medication.
                          <br></br>
                          <br></br>
                          Please select the best date an time for our pharmacist
                          to call you
                          <br></br>
                        </p>
                      </div>
                      <div className="nhsuk-radios">
                        <CustomDateTimePicker
                          appointmentDate={appointmentDate}
                          setAppointmentDate={setAppointmentDate}
                        />
                      </div>
                      <div className="template_control">
                        <TextField
                          className="template_input_inner text_input_field"
                          id="outlined-required"
                          label="Do you want to put any notes for the pharmacy"
                          placeholder="Comments"
                          multiline
                          rows={4}
                          value={comments}
                          onChange={handleCommentsChange}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <button onClick={handleSuccessPage} className="nhsuk-button confirm_booking_button">
                    Confirm booking
                  </button>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </div>
  );
}

export default MakeBooking;
