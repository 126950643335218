import { dispatch } from "../store/store";
import { logError } from "../services/logService";
import {
  setNhsFormData,
  getConsentData,
  setIsConsentLoading,
  saveConsentFormData,
  setHealthyAdviceData,
  setBookingDateData,
  setIsReschedule,
  setConsentFormData,
  setBookingTimePeriodData,
} from "../features/consentSlice/consentSlice";
const reducerName = "consent";
export const setNhsForm = (data) => {
  dispatch(setNhsFormData(data));
};
export const setHealthyAdvice = (data) => {
  dispatch(setHealthyAdviceData(data));
};
export const setBookingDate = (data) => {
  dispatch(setBookingDateData(data));
};
export const setConsentLoading = (isLoading) => {
  dispatch(setIsConsentLoading(isLoading));
};
export const setRescheduleBooking = (value) => {
  dispatch(setIsReschedule(value));
};
export const setConsentFormModel = (model) => {
  dispatch(setConsentFormData(model));
}
export const setBookingTimePeriod = (time) => {
  dispatch(setBookingTimePeriodData(time));
}
export const getAllConsentData = async (serviceId) => {
  let result = [];
  try {
    result = await dispatch(getConsentData(serviceId)).unwrap();
    result[0].clinicalServiceID = serviceId;
  } catch (error) {
    result = [];
  }
  return result;
};
export const saveConsentData = async (serviceId, data) => {
  let result = null;
  try {
    result = await dispatch(saveConsentFormData({ serviceId, data })).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
