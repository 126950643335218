import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useBookingApp from "../../../hooks/useBookingApp";
import { TextField, FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ConfirmationAccordian from "./ConfirmationAccordian";
import NHSLogo from "../../../Assets/nhsLogo.jpg";

function UserConfirmation() {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { consentState } = useBookingApp();
  const { consentData } = consentState;
  const [{ PatientName, DOB, PharmacyName, DateOfIntervention } = {}] =
    consentData ?? {};
  const dateFormat = new Date(DOB);
  const jsonDateFormatString = dateFormat.toString();
  var jsonDateParts = jsonDateFormatString.split(" ");
  var formattedJsonDate = jsonDateParts.slice(0, 4).join(" ");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      surname: "",
      dateOfBirth: null,
    },
    validationSchema: Yup.object().shape({
      surname: Yup.string().required("Surname is required"),
      dateOfBirth: Yup.date().required("Date of birth is required"),
    }),
    onSubmit: (values) => {
      const SplitFullName = PatientName?.split(" ");
      const PatientSurName = SplitFullName[SplitFullName?.length - 1];
      const { surname, dateOfBirth } = values;
      const parsedDateOfBirth = new Date(dateOfBirth);
      var dateString = parsedDateOfBirth.toString();
      var dateParts = dateString.split(" ");
      var formattedInputDate = dateParts.slice(0, 4).join(" ");

      if (
        formattedJsonDate === formattedInputDate &&
        surname.toLowerCase() === PatientSurName.toLowerCase()
      ) {
        if (
          DateOfIntervention === null ||
          DateOfIntervention === undefined ||
          DateOfIntervention === "undefined"
        ) {
          navigate("/NewMedicineService");
        } else {
          navigate("/bookingUpdate");
        }
      } else {
        setErrorMessage("Surname or date of birth did not match");
      }
    },
  });

  return (
    <div className="user-confirmation">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{PharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-main-wrapper nhsuk-main-wrapper--s">
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <div className="nhsuk-form-group">
                  <div className="nhsuk-fieldset">
                    <h2>Confirm your identity</h2>
                    <br></br>
                    <p>
                      We need to confirm your identity before signing up to the
                      service
                    </p>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="d-flex">
                        <div className="nhsuk-radios">
                          <FormControl fullWidth>
                            <TextField
                              id="surname"
                              name="surname"
                              label="Surname"
                              variant="outlined"
                              value={formik.values.surname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.surname &&
                                Boolean(formik.errors.surname)
                              }
                              helperText={
                                formik.touched.surname && formik.errors.surname
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="nhsuk-radios">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                              <DesktopDatePicker
                                label="Date of birth"
                                value={formik.values.dateOfBirth}
                                onChange={(value) =>
                                  formik.setFieldValue("dateOfBirth", value)
                                }
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                error={
                                  formik.touched.dateOfBirth &&
                                  Boolean(formik.errors.dateOfBirth)
                                }
                                helperText={
                                  formik.touched.dateOfBirth &&
                                  formik.errors.dateOfBirth
                                }
                                format="DD-MM-YYYY"
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="nhsuk-radios">
                        {errorMessage && (
                          <div className="error-message">{errorMessage}</div>
                        )}
                      </div>
                      <ConfirmationAccordian />
                      <button type="submit" className="nhsuk-button">
                        Continue
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserConfirmation;
